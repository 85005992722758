import React from "react"
import styled, { ThemeProvider } from "styled-components"
import theme from "@igloonet-web/shared-ui/themes/igloonet"

import {
  Link,
  ContactFormJobs,
  TitleH2Center,
  PaddingHalfWrapper,
} from "@igloonet-web/shared-ui"

import {
  Accordion,
  JobHeader,
  JobIntroduction,
  JobMainPhoto,
  JobSection,
  JobWrapper,
} from "../../components"

import ProfileMichelin from "../../images/team/profile/michelin.jpg"
import ProfilePakin from "../../images/team/profile/pakin.jpg"
import ProfileBara from "../../images/team/profile/bara.jpg"
import ProfileSimca from "../../images/team/profile/simca.jpg"
import { BENEFITS } from "../../model/benefits.tsx"
import BenefitPenguin from "../../images/jobs/benefit_penguin.svg"
import JobsSlider from "../../components/jobs/jobs-slider"
import PositionStamp from "../../components/position-stamp"

const JobDetailBody = styled.div`
  position: relative;
`

const JobDetailContent = styled.div`
  display: block;
  margin: auto;
  line-height: 1.5;
  max-width: 690px;
`

const BackOfficeJobDetail = () => {
  const personSlider = [
    {
      id: 1,
      imageSrc: ProfileSimca,
      name: "Simca",
      position: "back office",
    },
    {
      id: 2,
      imageSrc: ProfileMichelin,
      name: "Michelin",
      position: "vedoucí administrativy",
    },
    {
      id: 3,
      imageSrc: ProfileBara,
      name: "Bára",
      position: "back office, personalistika ",
    },
    {
      id: 4,
      imageSrc: ProfilePakin,
      name: "Radek",
      position: "ekonom",
    },
  ]

  return (
    <ThemeProvider theme={theme}>
      <JobWrapper>
        <JobHeader />

        <JobDetailBody>
          <PositionStamp filled />
          <JobDetailContent className="px-2 px-lg-0">
            <JobIntroduction title="Posila do back office">
              <p>
                Ahoj, jsme <Link to="/">igloonet</Link> a&nbsp;už spoustu let
                děláme pod jednou střechou hosting, vývoj a&nbsp;marketing.
              </p>
              <p>
                TL;DR: Hledáme šikovnou parťačku nebo šikovného parťáka do
                našeho malého back office týmu v&nbsp;Brně. Někoho, kdo nám
                svojí pečlivostí a&nbsp;spolehlivostí pomůže zajistit každodenní
                chod našeho igloo, s&nbsp;chutí se ponoří do hlubin faktur
                a&nbsp;účetních dokladů, kdo bude komunikovat s&nbsp;našimi
                zákazníky po telefonu i&nbsp;e-mailu.
              </p>
            </JobIntroduction>

            <JobSection title="Proč je tato pozice důležitá?">
              <ul>
                <li>
                  Budeš spoluvytvářet zázemí a&nbsp;servis pro všechny ostatní
                  tučňáky v&nbsp;igloo.
                </li>
                <li>
                  Budeš často první osoba, kterou uslyší náš budoucí zákazník
                  v&nbsp;telefonu nebo se kterou si vymění pár e-mailů.
                </li>
                <li>
                  Díky tobě budeme do práce i&nbsp;na home office chodit ještě
                  spokojenější než doteď.
                </li>
              </ul>
            </JobSection>

            <JobSection title="Je práce pro tebe?">
              <p>
                <strong>Tvůj denní chleba</strong>
              </p>
              <ul>
                <li>
                  Administrativa: evidence účetních dokladů, pokladny a&nbsp;HW.
                </li>
                <li>
                  Komunikace: zvedání telefonu, řešení e-mailů a&nbsp;ticketů
                  v&nbsp;
                  <a
                    href="//redmine.org"
                    title="redmine.org"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Redmine
                  </a>
                </li>
                <li>
                  Provoz: od zařízení nákupu na{" "}
                  <a
                    href="//rohlik.cz"
                    title="rohlik.cz"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Rohlíku
                  </a>{" "}
                  přes uvaření kafe na schůzku po zařízení květinové výzdoby v
                  kancelářích.
                </li>
              </ul>

              <p>
                <strong>Na čem nám záleží</strong>
              </p>
              <ul>
                <li>
                  Je na tebe spoleh a&nbsp;pečlivost je tvoje druhé jméno. Bez
                  výmluv.
                </li>
                <li>
                  Potřebujeme tě v&nbsp;kanceláři v&nbsp;Brně od pondělí do
                  středy.
                </li>
                <li>
                  Umíš s&nbsp;počítačem a&nbsp;nezalekneš se jen tak nějaké
                  tabulky.
                </li>
                <li>
                  Faktura je tvůj kamarád a&nbsp;víš, že DÚZP neznamená Další
                  Úspěšně Zvládnutý Proces.
                </li>
                <li>
                  Schopnost organizace práce a&nbsp;času ti není cizí. Zvládáš
                  pracovat samostatně.
                </li>
                <li>
                  Zvládneš se poprat i&nbsp;s&nbsp;rutinními nebo méně sexy
                  úkoly.
                </li>
                <li>
                  Nezavěsíš v&nbsp;panice, když se v&nbsp;telefonu ozve: „Hello,
                  do you speak English?“
                </li>
              </ul>
              <p>
                <strong>Na čem nám naopak nezáleží</strong>
              </p>
              <ul>
                <li>
                  Kolik je ti zim, jestli čůráš ve stoje nebo v&nbsp;sedě, jak
                  se oblékáš, s&nbsp;kým chceš žít nebo kdo jsou tví rodiče.
                </li>
                <li>
                  Na tvém vzdělání. Fakt ne. Ani na maturitě, ani na titulech,
                  ani na certifikátech či diplomech.
                </li>
              </ul>
              <p>
                <strong>Naše sny</strong>
              </p>
              <ul>
                <li>Být v&nbsp;pohodě, i&nbsp;když ostatní nejsou.</li>
                <li>
                  S&nbsp;úsměvem napomínat po milionté ostatní za to, že
                  nedodali tu fakturu nebo že neuklidili ten hrnek od kafe.
                </li>
                <li>Jsi tak pečlivá/ý a&nbsp;spolehlivá/ý, až je to nudné.</li>
                <li>Účetnictví máš v&nbsp;malíku.</li>
                <li>Říkají ti Excelový ninja.</li>
              </ul>
            </JobSection>

            <JobSection title="Co ti nabízíme?">
              <ul>
                <li>
                  O výši tvé mzdy se budeme bavit hned na prvním pohovoru.
                  Hrubou představu máme, chceme ji ale přizpůsobit tvým
                  zkušenostem i&nbsp;očekávání.
                </li>
                <li>
                  Pestrou práci v&nbsp;přátelském a&nbsp;neformálním prostředí.
                </li>
                <li>
                  Pravidelný, ale nikoliv nucený home office každý čtvrtek
                  a&nbsp;pátek.
                </li>
                <li>Možnost zkráceného úvazku na 0,8.</li>
                <li>
                  Spousty benefitů včetně sickdays, příspěvku na obědy nebo
                  neustále plné ledničky. Na celý seznam můžeš mrknout níž.
                </li>
              </ul>
            </JobSection>

            <PaddingHalfWrapper>
              <section>
                <TitleH2Center>Co tě u nás čeká?</TitleH2Center>
                <p>
                  Hledáme někoho, kdo nám věnuje 0,8 až celý úvazek svého
                  drahocenného času. Pondělí až středa s&nbsp;námi
                  v&nbsp;kanceláři, ve čtvrtek a&nbsp;pátek klidně
                  z&nbsp;karavanu zaparkovaného pod sjezdovkou.
                </p>
                <p>
                  Náplň práce je poměrně široká, ale hlavní části jsou: evidence
                  všech možných dokladů do interních systémů, starost
                  o&nbsp;provoz kanceláří a&nbsp;komunikace po telefonu, e-mailu
                  a&nbsp;hlavně v&nbsp;našem hlavním systému Redmine. Počítej
                  s&nbsp;tím, že tě do všeho zaučíme a&nbsp;ukážeme ti, jak co
                  dělat. Postupem času ti ale celou agendu předáme na
                  zodpovědnost.
                </p>
                <p>
                  Zásadní je pro nás opravdu spolehlivost a&nbsp;pečlivost.
                  Budeš člověk, který kontroluje ostatní, nesmí zapomínat
                  a&nbsp;odkládat věci. Práce je to opravdu různorodá
                  a&nbsp;občas může být i&nbsp;stresující. Je tedy potřeba umět
                  správně prioritizovat úkoly a zorganizovat svůj čas.
                </p>
              </section>
            </PaddingHalfWrapper>

            <PaddingHalfWrapper>
              <section>
                <TitleH2Center>Kdo tě povede?</TitleH2Center>
                <JobMainPhoto src={ProfileMichelin} imgTitle="Michelin">
                  <p className="pt-2">
                    Michelin je jednatel a&nbsp;spolumajitel. V&nbsp;igloo má
                    kromě peněz a smluv na starosti, aby vše po administrativní
                    stránce šlapalo na 100&nbsp;%. Spolu s&nbsp;Bárou dohlédne
                    na to, že tvé nalodění proběhne bez problémů a&nbsp;budeš
                    v&nbsp;týmu co nejspokojenější.
                  </p>
                </JobMainPhoto>
              </section>
            </PaddingHalfWrapper>

            <JobsSlider data={personSlider} style={{ minHeight: "15rem" }} />

            <Accordion
              modal
              mainTitle="Co dostaneš za benefity?"
              data={BENEFITS}
              mainImage={BenefitPenguin}
            />
          </JobDetailContent>

          <ContactFormJobs
            heading="Napiš Michelinovi a zbytečně to neodkládej"
            modal
            lang="csTykani"
          />
        </JobDetailBody>
      </JobWrapper>
    </ThemeProvider>
  )
}

export default BackOfficeJobDetail
