import React, { useEffect } from "react"
import { Layout } from "../../components"
import OgImage from "../../images/position/og-image-back-office.png"
import { Helmet } from "react-helmet"
import { Banner, BannerStyle } from "@igloonet-web/shared-ui"
import BackOfficeJobDetail from "./posila-do-back-office-job-detail"

export default function BackOffice() {
  useEffect(() => {
    document.body.classList.add("modal-open")

    return function cleanup() {
      document.body.classList.remove("modal-open")
    }
  }, [])
  return (
    <Layout>
      <Helmet>
        <title>Hledáme posilu do back office | igloonet</title>
        <meta
          name="description"
          content="Hledáme šikovnou parťačku nebo šikovného parťáka do našeho malého back office týmu v Brně. Někoho, kdo nám pomůže zajistit každodenní chod igloo, s chutí se ponoří do hlubin faktur i účetních dokladů a bude vytvářet firemní atmosféru."
        />
        <meta property="og:title" content="Hledáme posilu do back office" />
        <meta property="og:image" content={OgImage} />
      </Helmet>

      <Banner
        style={BannerStyle.Jobs}
        orangeHeading="Každý strávíme třetinu života v práci."
        blackHeading="Vybírej chytře, s kým ji prožiješ"
        text="Chceš pracovat v kolektivu, kde bude tvé slovo slyšet?
        Staň se tučňákem a pracuj v igloo. #nejsmezledu"
      />

      <BackOfficeJobDetail />
    </Layout>
  )
}
